<template>
    <div>
        <div class="tableBox">
            <sn-table-group :tableGroupAttributes.sync="tableGroupAttributes" @handleChange="handleChange"></sn-table-group>
        </div>
        <div class="equipment">
            <el-dialog :close-on-click-modal="false" title="设备历史记录" :append-to-body="true" :visible.sync="showhistory">
                <div class="history scrollbar">
                    <ul>
                        <i class="line"></i>
                        <li v-for="(item,index) in historylist" v-if="historylist.length !== 0" :key="index" class="item">
                            <div v-if="item.STARTTIME" class="clearfloat state stateNormal">
                                <i class="green"></i>
                                <span>正常</span>
                                <span>{{item.STARTTIME}}</span>
                            </div>
                            <div v-if="item.STOPTIME" class="clearfloat state stateFault">
                                <i class="red"></i>
                                <span>故障</span>
                                <span>{{item.STOPTIME}}</span>
                            </div>
                        </li>
                    </ul>
                    <div v-if="historylist.length === 0">
                        <span>暂无数据</span>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" type="danger" @click="showhistory = false">取 消</el-button>
                </span>
            </el-dialog>
            <el-dialog :close-on-click-modal="false" title="设备详情" :append-to-body="true" :visible.sync="showequiment">
                <div v-if="equipmentinfodata !== null" class="equipmentinfo scrollbar">
                    <div class="item">
                        <div class="part">
                            <span>设备名称:</span>
                            <div class="listitem">{{equipmentinfodata.SBMC}}</div>
                        </div>
                        <div class="part">
                            <span>设备编号:</span>
                            <div class="listitem">{{equipmentinfodata.SBBH}}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="part">
                            <span>监测类型:</span>
                            <div class="listitem">{{equipmentinfodata.JCXMC}}</div>
                        </div>
                        <div class="part">
                            <span>安装位置:</span>
                            <div class="listitem">{{equipmentinfodata.WZMS}}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="part">
                            <span>经度:</span>
                            <div class="listitem">{{equipmentinfodata.JD}}</div>
                        </div>
                        <div class="part">
                            <span>纬度:</span>
                            <div class="listitem">{{equipmentinfodata.WD}}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="part">
                            <span>设备状态:</span>
                            <div class="listitem">{{equipmentinfodata.SBZTNAME}}</div>
                        </div>
                        <div class="part">
                            <span>安装日期:</span>
                            <div class="listitem">{{equipmentinfodata.AZSJ}}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="part">
                            <span>安装单位:</span>
                            <div class="listitem">{{equipmentinfodata.AZDW}}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="img">
                            <span>设备照片:</span>
                            <div class="photo">
                                <img v-for="(item,index) in photolist" :key="index" :src="item" @click="openimg(item)" />
                            </div>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { commonData } from '../sn-table-group-common';
import { selfData } from './sn-table-group-equipment';
import commonMethodMixin from '../commonMethodMixin';
export default {
    name: 'equipment',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            keyword: '', // 关键字
            equipmentstate: null,
            showhistory: false,
            historylist: [],
            showequiment: false,
            equipmentinfodata: null,
            photolist: [],
            ycqid: null,
            jclx: null,
            jclxlist: [],
            timer: null
        };
    },
    mounted() {
        // let _this = this;
        // this.getequipment();
        // this.getjclxlist();
        // this.getwarn();
        // this.timer = setInterval(function() {
        //     _this.getwarn();
        //     _this.getequipment();
        // }, 300000);
    },
    destroyed() {
        clearTimeout(this.timer);
    },
    methods: {
        handleRead(val) {
            this.showequiment = true;
            this.equipmentinfodata = val;
        },
        handleReadHistory(val) {
            this.gethistory(val.SBBH);
        },
        async getjclxlist() {
            // 获得设备列表
            let res = await clientBHL('GetJclxList', []);
            let da = JSON.parse(res).ResultValue;
            da.forEach(item => {
                item.NAME = item.JCXMC;
                item.ID = item.JCXMC;
            });
            this.jclxlist = da;
            this.searchData.select[1].list = da;
        },
        async getequipmentinfo(id) {
            let res = await clientBHL('GetEquipmentInfo', [id]);
            let da = JSON.parse(res).ResultValue;
            this.showequiment = true;
            this.equipmentinfodata = da[0];
            this.photolist = da[0].SBZP ? da[0].SBZP.split(',') : [];
        },
        async gethistory(id) {
            // 根据id获得设备详情
            let res = await clientBHL('GetEquipmentHistory', [id]);
            let da = JSON.parse(res).ResultValue;
            this.historylist = da;
            this.showhistory = true;
        },
        openimg(src) {
            window.open(src);
        },
        // 设置故障设备不提醒
        async nowarn(id, num, index) {
            // let res = await clientBHL('UpdateEquipmentTxzt', [id, num])
            // let da = JSON.parse(res).ResultValue;
            // if (da.IsSuccess) {
            //     this.$message({
            //         type: 'success',
            //         message: '修改成功'
            //     })
            //     this.equipList[index].TXZT = num
            // } else {
            //     this.$message({
            //         type: 'error',
            //         message: '修改失败'
            //     })
            // }
        },
        // 获得是否需要预警
        async getwarn() {
            // let res = await clientBHL('GetEquipmentYjRemind', [])
            // let da = JSON.parse(res).ResultValue;
            // if (da.IsSuccess && da.ResultValueValue == '1') {
            //     var audio = document.createElement('audio')
            //     audio.src = '../../../../static/image/warn.mp3'
            //     audio.play()
            // }
        }
    }
};
</script>
<style scoped>
    .equipselect {
        color: #2578b5;
        float: left;
    }

    .equipselect .option {
        width: 160px;
        margin-right: 5px;
        margin-top: -4px;
        vertical-align: middle;
    }

    .history {
        max-height: 300px;
        overflow-y: auto;
        position: relative;
    }
    .history ul {
        display: block;
        position: relative;
    }
    .history .line {
        width: 1px;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;
        background: #ccc;
    }
    .history .item {
        overflow: hidden;
        padding-top: 0px;
        position: relative;
        margin-bottom: 15px;
    }

    .history .state {
        width: 50%;
        margin-left: 50%;
        line-height: 24px;
        position: relative;
    }
    .history .stateNormal i {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        z-index: 4;
    }
    .history .state i,
    .history .state span {
        float: left;
    }
    .history .state span {
        margin: 0 8px;
    }
    .history .stateFault {
        margin-left: 0;
    }
    .history .stateFault i {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        z-index: 4;
    }
    .history .stateFault i,
    .history .stateFault span {
        float: right;
    }
    .green,
    .red {
        width: 14px;
        height: 14px;
        border: 2px solid #fff;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box
    }
    .green {
        border-color: green;
    }

    .red {
        border-color: red;
    }

    .equipmentinfo .item {
        clear: both;
        min-height: 35px;
    }

    .equipmentinfo .item .part {
        width: 50%;
        float: left;
    }

    .equipmentinfo .item .part span {
        width: 80px;
        text-align: right;
        margin-right: 10px;
        display: block;
        float: left;
    }

    .equipmentinfo .item .img span {
        width: 80px;
        text-align: right;
        margin-right: 10px;
        display: block;
        float: left;
    }

    .photo {
        min-height: 100px;
        float: left;
        width: 580px;
    }

    .photo img {
        display: block;
        float: left;
        width: 180px;
        height: 180px;
        margin: 5px;
        cursor: pointer;
    }

    .photo img:hover {
        opacity: 0.8;
    }

    .listitem {
        width: calc(100% - 100px);
        float: left;
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }
</style>