const selfData = {
    snTable: {
        table: {
            data: [{
                id: 1,
                SBMC: '设备1',
                WZMS: '位置1',
                JCXMC: '监测项1',
                SBBH: '设备编号1',
                SBZTNAME: '设备状态1',
                AZSJ: '2018-10-15',
                STOPTIME: '2019-05-28',
            }, {
                id: 2,
                SBMC: '设备2',
                WZMS: '位置2',
                JCXMC: '监测项2',
                SBBH: '设备编号2',
                SBZTNAME: '设备状态2',
                AZSJ: '2018-10-15',
                STOPTIME: '2019-05-28',
            }],
            rowKey: 'id',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "设备名称",
                prop: "SBMC"
            },
            {
                label: "安装位置",
                prop: "WZMS"
            },
            {
                label: "监测类型",
                prop: "JCXMC"
            },
            {
                label: "设备编码",
                prop: "SBBH"
            },
            {
                label: "设备状态",
                prop: "SBZTNAME"
            },
            {
                label: "安装时间",
                prop: "AZSJ"
            },
            {
                label: "故障时间",
                prop: "STOPTIME"
            }
            ],
        },
    },
    snSearch: {
        selectData: [{
            label: '设备状态',
            clearable: true,
            placeholder: "请选择设备状态",
            list: [{
                ID: 1,
                NAME: '正常',
            },
            {
                ID: 0,
                NAME: '故障'
            }
            ],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'equipmentstate',
            operateType: 'search',
            isShow: true
        }, {
            label: '监测类型',
            clearable: true,
            placeholder: "请选择监测类型",
            list: [{
                ID: 1,
                NAME: '正常',
            },
            {
                ID: 0,
                NAME: '故障'
            }
            ],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'equipmentstate',
            operateType: 'search',
            isShow: true
        }],
    }
};
export { selfData };